import React from 'react';
import { lazy, redirect, route } from 'navi';
import AccessibilityStatement from '../components/AccessibilityStatement';
import ROUTE_TITLES from './RouteTitles';
import PrivacyAndCookiePolicy from '../components/PrivacyAndCookiePolicy';
import ROUTE_PATHS from './RoutePaths';
import { withAuthentication } from './utils';
import ExistingAccountPage from '../pages/account/ExistingAccountPage';
import Confirmation from '../pages/forms/Confirmation';
import ConfirmationCOIR from '../pages/forms/ConfirmationCOIR';
import FormPage from '../pages/forms/FormPage';
import FormPageSubmission from '../pages/forms/FormPageSubmission';
import NewAccountPage from '../pages/account/NewAccountPage';
import PendingAccountPage from '../pages/account/PendingAccountPage';

const activeUserRootRedirectMatcher = (request, context) =>
  withAuthentication(
    request.params.redirectTo
      ? redirect(decodeURIComponent(request.params.redirectTo))
      : route({
          title: context.t(ROUTE_TITLES.ROOT),
          getView: () => import('../pages/home'),
        })
  );

const accessibilityMatcher = (request, context) =>
  route({
    title: context.t(ROUTE_TITLES.ACCESSIBILITY),
    view: <AccessibilityStatement />,
  });

const privacyMatcher = (request, context) =>
  route({
    title: context.t(ROUTE_TITLES.PRIVACY),
    view: <PrivacyAndCookiePolicy />,
  });

const formsMatcher = () => import('../pages/forms/routes');
const reportsMatcher = () => import('../pages/reports/routes');
const airpaxTargetsMatcher = (request) =>
  redirect(`${ROUTE_PATHS.TARGETS_AIRPAX}/${request.params.targetId}`);
const roroTargetsMatcher = (request) =>
  redirect(`${ROUTE_PATHS.TARGETS_RORO}/${request.params.targetId}`);
const targetsMatcher = () => import('../pages/targets/routes');
const tasksMatcher = () => import('../pages/tasks/routes');
const mandecsMatcher = () => import('../pages/mandecs/routes');
const casesMatcher = () => import('../pages/cases/routes');
const tmsMatcher = () => import('../pages/tms/routes');

const logoutMatcher = (request, context) =>
  withAuthentication(
    route({
      title: context.t(ROUTE_TITLES.LOGOUT),
      getView: () => import('../components/Logout'),
    })
  );

const loginMatcher = async (request, context) =>
  context.isAuthenticated
    ? redirect(
        request.params.redirectTo ? decodeURIComponent(request.params.redirectTo) : ROUTE_PATHS.ROOT
      )
    : lazy(() => import('../pages/home/LoginPage'));

const existingAccountMatcher = (request, context) =>
  withAuthentication(
    route({
      title: context.t(ROUTE_TITLES.EXISTING_ACCOUNT),
      view: <ExistingAccountPage />,
    })
  );

const confirmationMatcher = (request, context) =>
  withAuthentication(
    route({
      title: context.t(ROUTE_TITLES.CONFIRMATION),
      view: <Confirmation reference={request.params.ref} />,
    })
  );

const confirmationCoirMatcher = (request, context) =>
  withAuthentication(
    route({
      title: context.t(ROUTE_TITLES.CONFIRMATION_COIR),
      view: <ConfirmationCOIR reference={request.params.ref} />,
    })
  );

const inactiveUserRootRedirectMatcher = (request, context) =>
  !context.pending ? redirect(ROUTE_PATHS.NEW_ACCOUNT) : redirect(ROUTE_PATHS.ACCOUNT_UNDER_REVIEW);

const formsAccountMatcher = (request, context) =>
  !context.pending
    ? withAuthentication(
        route({
          title: context.t(ROUTE_TITLES.FORMS_ACCOUNT),
          view: <FormPage processKey="account" />,
        })
      )
    : redirect(ROUTE_PATHS.ACCOUNT_UNDER_REVIEW);

const accountConfirmationMatcher = (request, context) =>
  !context.pending
    ? withAuthentication(
        route({
          title: `${context.t(ROUTE_TITLES.ACCOUNT_CONFIRMATION)} - Create An Account - ${context.t(
            ROUTE_TITLES.SERVICE_NAME
          )}`,
          view: <FormPageSubmission businessKey={request.params.businessKey} />,
        })
      )
    : redirect(ROUTE_PATHS.ACCOUNT_UNDER_REVIEW);

const newAccountMatcher = (request, context) =>
  context.pending
    ? redirect(ROUTE_PATHS.ACCOUNT_UNDER_REVIEW)
    : withAuthentication(
        route({
          title: context.t(ROUTE_TITLES.NEW_ACCOUNT),
          view: <NewAccountPage key={`na-${Date.now()}`} />,
        })
      );

const accountUnderReviewMatcher = (request, context) =>
  context.pending
    ? withAuthentication(
        route({
          title: context.t(ROUTE_TITLES.ACCOUNT_UNDER_REVIEW),
          view: <PendingAccountPage key={`na-${Date.now()}`} />,
        })
      )
    : redirect(ROUTE_PATHS.NEW_ACCOUNT);

export {
  accessibilityMatcher,
  privacyMatcher,
  formsMatcher,
  reportsMatcher,
  airpaxTargetsMatcher,
  roroTargetsMatcher,
  targetsMatcher,
  tasksMatcher,
  mandecsMatcher,
  casesMatcher,
  tmsMatcher,
  logoutMatcher,
  loginMatcher,
  existingAccountMatcher,
  confirmationMatcher,
  confirmationCoirMatcher,
  activeUserRootRedirectMatcher,
  formsAccountMatcher,
  accountConfirmationMatcher,
  newAccountMatcher,
  accountUnderReviewMatcher,
  inactiveUserRootRedirectMatcher,
};

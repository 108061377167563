import { map, lazy, mount, redirect } from 'navi';
import ROUTE_PATHS from './RoutePaths';
import {
  accessibilityMatcher,
  accountConfirmationMatcher,
  accountUnderReviewMatcher,
  airpaxTargetsMatcher,
  casesMatcher,
  confirmationCoirMatcher,
  confirmationMatcher,
  existingAccountMatcher,
  formsAccountMatcher,
  formsMatcher,
  loginMatcher,
  logoutMatcher,
  mandecsMatcher,
  newAccountMatcher,
  privacyMatcher,
  reportsMatcher,
  activeUserRootRedirectMatcher,
  roroTargetsMatcher,
  targetsMatcher,
  tasksMatcher,
  tmsMatcher,
  inactiveUserRootRedirectMatcher,
} from './RouteMatchers';

const activeUserRoutes = mount({
  [ROUTE_PATHS.ROOT]: map(activeUserRootRedirectMatcher),
  [ROUTE_PATHS.ACCESSIBILITY]: map(accessibilityMatcher),
  [ROUTE_PATHS.PRIVACY]: map(privacyMatcher),
  [ROUTE_PATHS.FORMS]: lazy(formsMatcher),
  [ROUTE_PATHS.SUBMIT_FORM]: lazy(formsMatcher),
  [ROUTE_PATHS.REPORTS]: lazy(reportsMatcher),
  [ROUTE_PATHS.TARGETS]: redirect(ROUTE_PATHS.TARGETS_RORO),
  [ROUTE_PATHS.AIRPAX_TARGETS]: redirect(ROUTE_PATHS.TARGETS_AIRPAX),
  [`${ROUTE_PATHS.AIRPAX_TARGETS}/:targetId`]: map(airpaxTargetsMatcher),
  [ROUTE_PATHS.RORO_TARGETS]: redirect(ROUTE_PATHS.TARGETS_RORO),
  [`${ROUTE_PATHS.RORO_TARGETS}/:targetId`]: map(roroTargetsMatcher),
  [ROUTE_PATHS.TARGETS_AIRPAX]: lazy(targetsMatcher),
  [ROUTE_PATHS.TARGETS_RORO]: lazy(targetsMatcher),
  [ROUTE_PATHS.TASKS]: lazy(tasksMatcher),
  [ROUTE_PATHS.MANDECS]: lazy(mandecsMatcher),
  [ROUTE_PATHS.CASES]: lazy(casesMatcher),
  [ROUTE_PATHS.TMS]: lazy(tmsMatcher),
  [ROUTE_PATHS.LOGOUT]: map(logoutMatcher),
  [ROUTE_PATHS.LOGIN]: map(loginMatcher),
  [ROUTE_PATHS.FORMS_ACCOUNT]: redirect(ROUTE_PATHS.EXISTING_ACCOUNT),
  [ROUTE_PATHS.EXISTING_ACCOUNT]: map(existingAccountMatcher),
  [ROUTE_PATHS.CONFIRMATION]: map(confirmationMatcher),
  [ROUTE_PATHS.CONFIRMATION_COIR]: map(confirmationCoirMatcher),
});

const inactiveUserRoutes = mount({
  [ROUTE_PATHS.ROOT]: map(inactiveUserRootRedirectMatcher),
  [ROUTE_PATHS.ACCESSIBILITY]: map(accessibilityMatcher),
  [ROUTE_PATHS.PRIVACY]: map(privacyMatcher),
  [ROUTE_PATHS.FORMS_ACCOUNT]: map(formsAccountMatcher),
  [`${ROUTE_PATHS.ACCOUNT_CONFIRMATION}/:businessKey`]: map(accountConfirmationMatcher),
  [`${ROUTE_PATHS.FORMS_ACCOUNT}/:formFragment`]: map(formsAccountMatcher),
  [ROUTE_PATHS.LOGOUT]: map(logoutMatcher),
  [ROUTE_PATHS.LOGIN]: map(loginMatcher),
  [ROUTE_PATHS.NEW_ACCOUNT]: map(newAccountMatcher),
  [ROUTE_PATHS.ACCOUNT_UNDER_REVIEW]: map(accountUnderReviewMatcher),
});

const getBaseRoutesForUser = (isActiveUser) => {
  return isActiveUser ? activeUserRoutes : inactiveUserRoutes;
};

export default getBaseRoutesForUser;
